import {
  Badge,
  Box,
  Circle,
  Container,
  Divider,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { updateBasketProductsThunk } from "@/modules/basket/data/thunks/UpdateBasketProductsThunk";
import {
  Carousel,
  ReturnToLastPageButton,
  useAppDispatch,
  useMoney,
} from "@/modules/common";
import { ChangeProductAmount } from "@/modules/common/components/ChangeProductAmount";
import { isHttpProblemDetail } from "@/modules/notifications/data/IsHttpProblemDetail";
import { ProductDetailDto, ProductDto, ProductsApi } from "@/modules/products";
import { DetailSkeleton } from "@/modules/products/details/components";
import { useErrorModes } from "@/modules/auth/hooks/useErrorModes";

export function ProductDetailsPage() {
  useErrorModes();
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const [product, setProduct] = useState<ProductDetailDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const requestRef = useRef<() => void>();

  const formattedPriceWithVat = useMoney(product?.priceVAT || 0);
  const formattedPriceWithoutVat = useMoney(Number(product?.price || 0));

  function updateBasket(product: ProductDto, quantity: number) {
    if (requestRef.current) {
      requestRef.current();
    }

    const req = dispatch(updateBasketProductsThunk({ ...product, quantity }));

    requestRef.current = () => req.abort();
  }

  useEffect(() => {
    setIsLoading(true);
    ProductsApi.detail(slug!)
      .then(data => {
        if (isHttpProblemDetail(data)) return;
        setProduct(data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [slug]);

  if (isLoading) {
    return <DetailSkeleton />;
  }

  if (!product) {
    return <Navigate replace to="/" />;
  }

  return (
    <Container maxW="1248px" pb={8}>
      <ReturnToLastPageButton />
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacingX={16}
        letterSpacing={1}
        pb={6}
      >
        <Carousel
          images={[product.imageUrl!, ...product.additionalImageUrls]}
          video={product.videoUrl}
        />
        <Flex
          flexDir="column"
          gap={{ base: 4, lg: 8 }}
          mb={product.additionalImageUrls.length === 0 ? 4 : 0}
          mt={{ base: 8, lg: 0 }}
          w="full"
        >
          <Box data-cy="name" lineHeight={1.8}>
            {product.remark && (
              <HStack
                mb={{ base: 2, md: 5 }}
                fontWeight="bold"
                fontSize="sm"
                letterSpacing={1}
              >
                <Badge
                  px={2}
                  py={1}
                  color="bsBlack"
                  bg="bsGray.200"
                  borderRadius="5"
                >
                  {product.remark}
                </Badge>
              </HStack>
            )}
            <Text fontSize="2xl" fontWeight="bold" lineHeight={1.3}>
              {product.name}
            </Text>
            <Flex
              gap={{ base: 1, md: 2 }}
              alignItems={{ base: "start", md: "center" }}
              flexDir={{ base: "column", md: "row" }}
              lineHeight={1.2}
              mt={{ base: 2, md: 1 }}
            >
              <Text fontSize="sm" color="gray.400">
                Art. Nr: {product.articleNumber}
              </Text>
              {product.ean && (
                <>
                  <Divider
                    orientation="vertical"
                    h="3"
                    display={{ base: "none", md: "block" }}
                  />
                  <Text fontSize="sm" color="gray.400">
                    EAN: {product.ean}
                  </Text>
                </>
              )}
            </Flex>

            {product.description && (
              <Text
                justifyContent="start"
                fontSize="sm"
                alignItems="start"
                mt={5}
              >
                {product.description}
              </Text>
            )}
          </Box>
          {product.resaleInfo && (
            <>
              <Box lineHeight={1.5}>
                <Text textTransform="uppercase" color="gray.400" fontSize="xs">
                  Consumentenprijs
                </Text>
                <Text fontWeight="bold">{product.resaleInfo}</Text>
              </Box>
            </>
          )}

          <Flex
            w="full"
            justifyContent="space-between"
            alignItems="center"
            my={5}
          >
            {product.stock <= 0 && (
              <Text textAlign="left" fontSize="xs" color="black.500" mb={1}>
                Tijdelijk niet leverbaar
              </Text>
            )}
            {product.stock > 0 && (
              <VStack justifyContent="start" alignItems="start" lineHeight={1}>
                <ChangeProductAmount
                  {...product}
                  updateBasket={item => updateBasket(product, item.amount)}
                />
              </VStack>
            )}
            <VStack lineHeight={1} alignItems="end">
              <Text fontWeight="bold" fontSize={"lg"}>
                {formattedPriceWithoutVat}
              </Text>
              <Text fontSize="sm" color="gray.400" letterSpacing={1}>
                incl. btw {formattedPriceWithVat}
              </Text>
            </VStack>
          </Flex>
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
